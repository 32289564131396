html{
  height: 100%;
}
body {
 background:#3578E5;
  min-height:100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
#root{
  height: 100%;
}